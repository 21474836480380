import React, { useEffect } from "react";
import $ from "jquery"; // Importe a biblioteca jQuery

export const Navigation = (props) => {
  // Função para fechar o menu de navegação quando um item for clicado
  const closeNavbarOnClick = () => {
    $(".navbar-collapse").collapse("hide");
  };

  // Use useEffect para adicionar um ouvinte de evento ao carregar o componente
  useEffect(() => {
    // Quando um item da barra de navegação for clicado, chame a função para fechar o menu
    $(".navbar-nav li a").on("click", closeNavbarOnClick);
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="/img/logo.png" alt="Aeromax Logo"  />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#company2" className="page-scroll">
                Serviços
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Sobre Nós
              </a>
            </li>
            <li>
              <a href="#company" className="page-scroll">
                Empresa
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Nossa Equipe
              </a>
            </li>
            <li>
              <a href="#company3" className="page-scroll">
                Fale Conosco
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
