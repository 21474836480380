import React, { useState, useEffect } from "react";

export const About = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verifique o tamanho da tela aqui e defina isMobile com base nisso
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Adicione um ouvinte de redimensionamento para atualizar isMobile quando a tela for redimensionada
    window.addEventListener("resize", handleResize);

    // Chamada inicial para definir isMobile com base no tamanho da tela atual
    handleResize();

    // Limpe o ouvinte de redimensionamento quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="about" className={`container ${isMobile ? 'no-padding-bottom' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Sobre Nós</h2>
              <img
                src="img/about.jpg"
                className="img-responsive visible-xs-block"
                alt=""
              />
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Por que nos escolher?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 hidden-xs">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
