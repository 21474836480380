import React, { useState, useEffect } from "react";

export const Team = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Verifique o tamanho da tela aqui e defina isMobile com base nisso
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Adicione um ouvinte de redimensionamento para atualizar isMobile quando a tela for redimensionada
    window.addEventListener("resize", handleResize);

    // Chamada inicial para definir isMobile com base no tamanho da tela atual
    handleResize();

    // Limpe o ouvinte de redimensionamento quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="team" className={`container ${isMobile ? 'no-padding-bottom' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="team-text">
              <h2>Nossa Equipe</h2>
              <img
                src="img/team/team.jpg"
                className="img-responsive visible-xs-block"
                alt=""
              />
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
          <div className="col-md-6 hidden-xs">
            <img src="img/team/team.jpg" className="img-responsive" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
