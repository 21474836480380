import React from "react";

export const Company = (props) => {
  return (
    <div id="company" className="text-center company-container">
      <div className="container">
        <div className="section-title">
          <h2>Missão, Visão e Valores</h2>
          <p>
            Na Aeromax Drones, somos guiados por uma equipe apaixonada e dedicada, composta por especialistas experientes em drones e tecnologia aérea. Cada membro de nossa equipe traz consigo uma riqueza de conhecimento e experiência, combinada com uma paixão compartilhada por inovação e excelência.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`company-${i}`} className="col-md-4">
                  <i className={d.icon}></i>
                  <div className="company-desc">
                    <h3>{d.title}</h3>
                    <p>{d.description}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
